@supports selector(::-webkit-scrollbar) {
  ::-webkit-scrollbar-track,
  ::-webkit-scrollbar-track-piece,
  ::-webkit-scrollbar-corner,
  ::-webkit-scrollbar  {
    width: 6px;
    height: 6px;
    border: none;
    outline: none;
    background-color: transparent;
  }

  ::-webkit-scrollbar-track {
    border-radius: 1000px;
    background-color: var(--arg-ui-color-seperator-sep1);
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 1000px;
    background-color: var(--arg-ui-color-seperator-sep3);
  }
  
  :hover > ::-webkit-scrollbar-thumb {
      background-color: var(--arg-ui-color-text-secondary);
  }
}

@supports (scrollbar-width: auto) {
  & {
    scrollbar-width: 6px;
    scrollbar-color: transparent transparent;

    &:hover > {
      // first = thumb, second=track
      scrollbar-color:  var(--arg-ui-color-seperator-sep3) var(--arg-ui-color-seperator-sep1); 
    }
  }
}

